//
// Roboto
//

$font-face-roboto: (
    'thin': 100,
    'light': 300,
    'regular': 400,
    'medium': 500,
    'bold': 700,
    'black': 900
) !default;

@each $name, $weight in $font-face-roboto {
    @font-face {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: $weight;
      font-display: swap;
      src: url('./roboto/roboto-#{$name}.woff') format('woff');
    }
    @font-face {
      font-family: 'Roboto';
      font-style: italic;
      font-weight: $weight;
      font-display: swap;
      src: url('./roboto/roboto-#{$name}-italic.woff') format('woff');
    }
}

//
// Roboto Condensed
//

$font-face-roboto-condensed: (
    'light': 300,
    'regular': 400,
    'bold': 700
) !default;

@each $name, $weight in $font-face-roboto-condensed {
    @font-face {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: $weight;
      font-display: swap;
      src: url('./roboto-condensed/roboto-condensed-#{$name}.woff') format('woff');
    }
    @font-face {
      font-family: 'Roboto Condensed';
      font-style: italic;
      font-weight: $weight;
      font-display: swap;
      src: url('./roboto-condensed/roboto-condensed-#{$name}-italic.woff') format('woff');
    }
}
