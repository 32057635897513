@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("roboto-thin.10977b30.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("roboto-thin-italic.f589b79b.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("roboto-light.c446996a.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("roboto-light-italic.30e3b31d.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("roboto-regular.d36ac342.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("roboto-regular-italic.6330147c.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("roboto-medium.abf602fe.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("roboto-medium-italic.5dd5ccf3.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("roboto-bold.a28e9f65.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("roboto-bold-italic.23678ac6.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("roboto-black.7b28d783.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("roboto-black-italic.a3c3cf11.woff") format("woff");
}
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("roboto-condensed-light.af049f9d.woff") format("woff");
}
@font-face {
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("roboto-condensed-light-italic.7c4aac16.woff") format("woff");
}
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("roboto-condensed-regular.049d6105.woff") format("woff");
}
@font-face {
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("roboto-condensed-regular-italic.1fda34b6.woff") format("woff");
}
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("roboto-condensed-bold.6a465d28.woff") format("woff");
}
@font-face {
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("roboto-condensed-bold-italic.4644e3fc.woff") format("woff");
}
/*# sourceMappingURL=fonts.css.map */
